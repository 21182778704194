import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { HttpInterceptProvider } from './core/http-intercept/http-intercept.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '@env/environment';
import { OktaLoginLogsModel, OktaUserModel, UserModel, UserProfileModel } from './core/models/user.model';
import { FTNgIdleModule } from 'ft-ng-idle';
import { FTNgLoadingModule } from 'ft-ng-loading';
import { FTNgBoxDocumentViewerModule } from 'ft-ng-box-document-viewer';
 import { Router } from '@angular/router';
 import { OktaAuth } from '@okta/okta-auth-js';
 import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaService } from './core/services/okta.service';
import { LoggingService } from './core/services/logging.service';
import { ErrorHandlerService } from './core/services/error-handler.service';

const config = Object.assign({
  onAuthRequired:(oktaAuth:any,injector:Injector)=>{
                  const router =injector.get(Router);
                  router.navigate(['/login']);
  }
},environment.oidcConfig);
const oktaAuth = new OktaAuth(config);
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OktaAuthModule,
    FTNgLoadingModule,
    FTNgIdleModule.forRoot(environment.idleTimeoutConfig),
    FTNgBoxDocumentViewerModule
  ],
  providers: [
     CookieService,
     UserModel,
     OktaUserModel,
     UserProfileModel,
     OktaLoginLogsModel,
     HttpInterceptProvider,
     OktaService,
     LoggingService,
    //  {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenInterceptor,
    //   multi: true
    // },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {provide:OKTA_CONFIG,useValue: {oktaAuth}}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
