import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpInterceptProvider } from './core/http-intercept/http-intercept.service';
import { FTLogger } from './core/logger/logger.service';
import { UserModel, UserProfileModel, OktaUserModel, OktaLoginLogsModel } from './core/models/user.model';
import { OktaService } from '@app/core/services/okta.service';
import { Router } from '@angular/router';

const log = new FTLogger('App Service');

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public oktaLoginLogs:OktaLoginLogsModel[];

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
    private httpIntercept: HttpInterceptProvider,
    public userModel: UserModel,
    public userProfileModel: UserProfileModel,
    public oktaUserModel: OktaUserModel,
    public oktaLogsModel: OktaLoginLogsModel,
    private oktaService:OktaService,
    private router: Router,
  ) {
    this.http = new HttpClient(handler);
  }

  /**
   * Shortest name:  {@link fetchSystemMaintenance}
   * Full name:      {@link (fetchSystemMaintenance: void)}
   * @description fectch system maintenance response.
   */
  public fetchSystemMaintenance() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiConfig.auth + 'application/v2/mode',
        { headers: { 'Ocp-Apim-Subscription-Key': environment.apiConfig.APIMSubscriptionKey } })
        .subscribe(
          (response: any) => {
            if (response && !response.hasError) {
              resolve({ status: true, data: response.data });
            } else {
              // log.error(
              //   'fetchSystemMaintenance',
              //   `Error @fetchSystemMaintenance: ${JSON.stringify(response)}`
              // );
              resolve({ status: false, message: response.msg });
            }
          },
          (error: any) => {
            // log.error('Error @fetchSystemMaintenance: ' + error.mode);
            resolve({ status: false, message: error.message });
          }
        );
    });
  }

  /**
   * Shortest name:  {@link fetchClientId}
   * Full name:      {@link (fetchClientId: void)}
   * @description fetch cient ID information.
   */
  public fetchClientId() {
    return new Promise((resolve, reject) => {
      this.httpIntercept
        .get(environment.apiConfig.auth + 'user/me')
        .subscribe(
          (response: any) => {
            if (response && !response.hasError) {
              this.setUserData(response.data);
              resolve({ status: true, data: response.data });
            } else {
              log.error(
                'fetchClientId',
                `Error @fetchClientId: ${JSON.stringify(response)}`
              );
              reject({ status: false, message: response.msg });
            }
          },
          (error: any) => {
            log.error('Error @fetchClientId: ' + JSON.stringify(error));
            if (error.status === 401) {
              this.oktaService.logout();
            }
            else if (error.status === 403) {
              this.router.navigate(['/pagenotauthorised']);
            }else
            this.router.navigate(['/pagenotauthorised']);
            reject({ status: false, message: error.message });
          }
        );
    });
  }
  public fetchClientDetails() {
    return new Promise((resolve, reject) => {
      this.httpIntercept
        .get(environment.apiConfig.auth + 'user/me/profile')
        .subscribe(
          (response: any) => {
            if (response && !response.hasError) {
              this.userModel.userProfile.emailId = response.data.primaryEmail;
              this.userModel.userProfile.contactNo = response.data.contactNumber;
              resolve({ status: true, data: response.data });
            } else {
              log.error(
                'fetchClientId',
                `Error @fetchClientId: ${JSON.stringify(response)}`
              );
              reject({ status: false, message: response.msg });
            }
          },
          (error: any) => {
            log.error('Error @fetchClientId: ' + JSON.stringify(error));
            if (error.status === 401) {
              this.oktaService.logout();
            }
            if (error.status === 403) {
              this.router.navigate(['/pagenotauthorised']);
            }
            reject({ status: false, message: error.message, statuscode: error.status });
          }
        );
    });
  }

  public fetchClientIPAddress() {
    return new Promise((resolve, reject) => {
      this.http
        .get('https://api.ipify.org?format=json')
        .subscribe(
          (response: any) => {
            if (response) {
              resolve({ status: true, data: response.ip });
            } else {
              reject({ status: false, message: response });
            }
          },
          (error: any) => {
            reject({ status: false, message: error.message, statuscode: error.status });
          }
        );
    });
  }

  /**
   * 
   * @param ipAddress 
   * @param sinceDate 
   * @description fetch okta client login logs. 
   */
  public fetchOktaClientLoginLogs(ipAddress:any, loginDuration:any) {
    return new Promise((resolve, reject) => {
      this.httpIntercept
        .get(environment.apiConfig.auth + 'audit/client/login/logs?ipAddress=' + ipAddress + '&since=' + loginDuration)
        .subscribe(
          (response: any) => {
            if (response) {
              this.oktaLoginLogs = response;
              resolve({ status: true, data: response });
            } else {
              reject({ status: false, message: response });
            }
          },
          (error: any) => {
            reject({ status: false, message: error.message, statuscode: error.status });
          }
        );
    });
  }

  public healthCheck() {
    return new Promise((resolve, reject) => {
      this.http.get("https://azuswspauthservicedev.dev001.ase.frk.com/healthcheck")
        .subscribe(
          (response: String) => {
            resolve(response)
          },
          (error: any) => {
            resolve(error);
          }
        );
    });
  }

  /**
   * Shortest name:  {@link setUserData}
   * Full name:      {@link (setUserData: void)}
   * @description set user data to userModel.
   * @param userData: any
   */
  public setUserData(userData: UserProfileModel) {
    this.userModel.cuid = userData.cuid;
    this.userModel.userProfile = userData;
    this.userProfileModel = userData;
  }

  /**
   * Shortest name:  {@link setOktaUserData}
   * Full name:      {@link (setOktaUserData: void)}
   * @description set user data to userModel.
   * @param userData: any
   */
   public setOktaUserData(userData: OktaUserModel) {
    this.oktaUserModel.accessToken = userData.accessToken;
    this.oktaUserModel.userName = userData.userName;
    this.oktaUserModel.emailId = userData.emailId
  }
}
