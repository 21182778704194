import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { OktaAuth } from '@okta/okta-auth-js';
import OktaSignIn from '@okta/okta-signin-widget';
import { OktaUserModel, UserModel } from '../models/user.model';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OktaService {

  widget: any;
  public isUserLoggedIn: boolean;
  public isUserLoggedOut:boolean;
  public accessToken: string;
  public refreshToken: string;
  
  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth, private cookieService: CookieService,    
    private http: HttpClient,
    private router: Router,
    public oktaUserModel:OktaUserModel,
    public userModel:UserModel) {
      this.checkoutAccount();
      this.widget= new OktaSignIn({
        logo:'assets/imgs/logos/ft-logo.png',
        features:{
          registration:false,
          rememberMe :true,
          smsRecovery:false,
          emailRecovery:true,
          showPasswordToggleOnSignInPage :true
        },
        baseUrl:environment.oidcConfig.issuer.split('/oauth2')[0],
        clientId:environment.oidcConfig.clientId,
        redirectUri:environment.oidcConfig.redirectUri,
        authParams:{
          pkce:true,
          issuer:environment.oidcConfig.issuer,
          scopes:environment.oidcConfig.scope,
          ignoreSignature: true,
        },
        i18n: {
          en: {
           'errors.E0000004': 'Authentication failed.'      
          }      
         },     
        useClassicEngine: true
      })
   }

   getWidget() {
    return this.widget;
  }

  async checkoutAccount() {
    this.isUserLoggedIn = await this.oktaAuth.isAuthenticated();
    this.accessToken = await this.oktaAuth.getAccessToken();
    this.refreshToken = await this.oktaAuth.getRefreshToken();
    if (this.isUserLoggedIn && this.isUserLoggedOut === false) {
      let user = this.oktaAuth.getUser();
      this.oktaUserModel.accessToken = this.accessToken
      this.oktaUserModel.refreshToken = this.refreshToken;
      this.oktaUserModel.userName = (await user).name
      this.oktaUserModel.emailId = (await user).email
      this.userModel.oktaUserModel = this.oktaUserModel;
    }
   
  }
  
  async logout() {
    this.isUserLoggedOut = true;
    await this.oktaAuth.signOut();
  }

  getNewAccesToken() {
    const apiUrl = environment.oidcConfig.issuer + '/v1/token';
    let params = new HttpParams({
      fromObject: {
        authorization: 'Bearer ' + this.accessToken,
        grant_type: 'refresh_token',
        scope: 'offline_access',
        refresh_token: this.oktaUserModel.refreshToken,
        accept: 'application/json',
        client_id: environment.oidcConfig.clientId
      },
    });
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = { headers: headers };

    this.http.post(apiUrl, params.toString(), options).subscribe(
      (response: any) => {
        this.oktaUserModel.accessToken = response.access_token; 
        this.oktaUserModel.refreshToken = response.refresh_token;
        this.isUserLoggedIn = true;
        this.accessToken = response.access_token; 
        this.refreshToken = response.refresh_token;
        this.userModel.oktaUserModel = this.oktaUserModel;
      },
      (error: any) => {
        this.logout(); 
      }
    );
  }

}
