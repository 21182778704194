import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { FTNgLoadingService } from 'ft-ng-loading';
import { AppConfig } from '../configs/app-config';
import { UserModel } from '../models/user.model';
import { OktaService } from '../services/okta.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private oktaService: OktaService,
    private appService: AppService,
    private userModel: UserModel,
    private ftNgLoadingService: FTNgLoadingService
  ) {
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<any> {
    if (this.oktaService.isUserLoggedIn) {
      this.ftNgLoadingService.showLoader();
      await this.appService.fetchClientId();
      await this.appService.fetchClientDetails();
      if (!this.userModel.cuid) {
        this.router.navigate(['/pagenotauthorised']);
        return false;
      }
      return true;
    } else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}
