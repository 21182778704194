import { AppConfig } from '@app/core/configs/app-config';

export const environment = {
  production: true,
  // msalConfig: AppConfig.azureMSAL.prod,
  apiConfig: AppConfig.api.prod,
  idleTimeoutConfig: AppConfig.idleConfig,
  storagePrefix: AppConfig.storage.prod,
  digitalVault: AppConfig.digitalVault.prod,
  employeeAppConfig: AppConfig.employeeApp.prod,
  appInsights: AppConfig.appInsights.prod,
  oidcConfig: AppConfig.oidc.prod
};
