import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ElementRef, Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { UtilsService } from './utils.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public pageNotAuthorized: boolean;
  public pageNotEntitled: boolean;
  public locationUrl: string;
  public mainScrollViewport: CdkVirtualScrollViewport;
  public defaultScrollViewport: ElementRef;
  public hexColors: any = ['#7de3bb', '#7ac4f5', '#ffdd8a', '#fcbb81', '#a3f7f5', '#ecc8f7', '#ffc2c2', '#fcc2ef'];

  // sidenav component
  public sideNavDetailsConfig: any = {
    isEnabled: '',
    sidenavData: {},
    sideNavMetadata: {}
  };

  public isReleaseDone: boolean;
  public entitlements: any = [];
  public defaultPage: any;
  public currentRoute: string = '';
  public hideLogoutMenu: boolean = false;
  public userLoginError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private utilsService: UtilsService
  ) { }

  /**
   * Shortest name:  {@link getUserObjectId}
   * Full name:      {@link (getUserObjectId: void)}
   * @description get msal ID token.
   */
  public getUserObjectId() {
    // if (sessionStorage.getItem('msal.idtoken') !== null) {
    //   const userOid = jwt_decode(sessionStorage.getItem('msal.idtoken'));
    //   return userOid.oid;
    // }
    return null;
  }

  /**
   * Shortest name:  {@link onViewportScroll}
   * Full name:      {@link (onViewportScroll: void)}
   * @description set scroll.
   * @param event: any
   * @param requestPayload: any
   * @param paging: any
   * @param callbackFunc: any
   * @param isSideNav: any
   */
  onViewportScroll(event: any, requestPayload: any, paging: any, callbackFunc?: any, isSideNav?: boolean) {
    if (!event) {
      return false;
    }
    const tableViewHeight = event.target.offsetHeight;
    const tableScrollHeight = event.target.scrollHeight;
    const scrollLocation = event.target.scrollTop;
    const buffer = isSideNav ? 40 : 190;
    const limit = tableScrollHeight - tableViewHeight - paging.bufferLength - buffer;
    if (scrollLocation > Math.max(0, limit) && requestPayload.offset < paging.totalRecords) {
      if (typeof callbackFunc === 'function') {
        callbackFunc();
        // Resize event trigger is needed to reflect changes in dom for impersonation mode
        this.utilsService.resizeEventDispatcher();
      }
    }
  }
}
