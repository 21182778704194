import { FTNgIdleConfig } from 'ft-ng-idle/lib/ft-ng-idle.config';
import {
    AppAPI,
    AppAPIConfig,
    AppBasicConfig,
    AppConfigMaster,
    AppInsights,
    AppInsightConfig,
    AppRouteConfig,
    AppStorageConfig,
    AppSupportConfig,
    DigitalVault,
    DigitalVaultConfig,
    EmployeeApp,
    EmployeeAppConfig,
    StorageConfig,
    OIDCConfig,
    oktaAuthConfig
} from './configurations';

const basicConfig: AppBasicConfig = {
    type: 'client', // employee || client
    title: 'Secure Portal',
    logo: 'ft-logo.png',
    logoLarge: 'ft-logo-black.png',
    logoWhite: 'ft-logo-white.png',
    logoSymbol: 'ft-logo-symbol.png',
    pageTitleSuffix: ' | RAD Secure Portal',
};

const routeConfig: AppRouteConfig = {
    protectedRouterUrl: 'sp',
    defaultPage: 'home'
};

const storageConfigDev: StorageConfig = {
    storagePrefix: 'sp.dev.',
    default: 'session' // session || local || cookie
};

const storageConfigProd: StorageConfig = {
    storagePrefix: 'sp.',
    default: 'session' // session || local || cookie
};

const storageConfig: AppStorageConfig = {
    dev: storageConfigDev,
    prod: storageConfigProd
};


const apiConfigDev: AppAPIConfig = {
    resourceURL: 'https://testresources.ftci.com/',
    APIMSubscriptionKey:'6be3cf15e2f64f75973c23db5f55a47e',//'058ed81fc0224c92a188b88a973b1084',
    auth: 'https://api-dev.frk.com/v1/secureportal/',//'https://azuswspauthservicedev.dev001.ase.frk.com/',
    documents: 'https://api-dev.frk.com/v1/secureportal/',//'https://azuswspdocservicedev.dev001.ase.frk.com/'
};

const apiConfigProd: AppAPIConfig = {
    resourceURL: 'https://testresources.ftci.com/', 
    APIMSubscriptionKey: 'c081df1d12354619bc3e8453ca5568e2',         	
    auth:'https://api.frk.com/v1/secureportal/',
    documents:'https://api.frk.com/v1/secureportal/',
};

export const oidcDev: OIDCConfig = {
    issuer: 'https://ftext.oktapreview.com/oauth2/default',
    clientId: '0oa81d4zm3BjQ5p001d7',
    redirectUri: 'https://dev-secureportal.franklintempleton.com/login/callback',//'https://azuswportaldev.dev001.ase.frk.com/login/callback',//'http://localhost:4200/login/callback',
    postLogoutRedirectUri: 'https://dev-secureportal.franklintempleton.com/logout',//'https://azuswportaldev.dev001.ase.frk.com/logout',//'http://localhost:4200/logout',
    scope:['openid','profile','email', 'offline_access']
}

export const oidcProd: OIDCConfig= {
    issuer: 'https://ftext.okta.com/oauth2/default',
    clientId: '0oa2s46a64XND5jcA697',
    redirectUri:'https://secureportal.franklintempleton.com/login/callback',
    postLogoutRedirectUri:'https://secureportal.franklintempleton.com/logout',
    scope:['openid','profile','email','offline_access']
}

const apiConfig: AppAPI = {
    dev: apiConfigDev,
    prod: apiConfigProd
};

export const oidcAuth : oktaAuthConfig = {
    dev: oidcDev,
    prod: oidcProd
}

const appInsightDev: AppInsightConfig = {
    instrumentationKey: '6e7b9b92-9659-452b-9c9d-9d2e344e60a2'
}

const appInsightProd: AppInsightConfig = {
    instrumentationKey: '70aa1568-d90e-4045-b4cd-396ed9fdfcfc'
}

const appInsights: AppInsights = {
    dev: appInsightDev,
    prod: appInsightProd
}

const ftIdleConfig: FTNgIdleConfig = {
    idle:3000,
    timeout: 500,
    ping: 600
};

const supportConfig: AppSupportConfig = {
    message: `Can’t login? Please call or email your Franklin Templeton contact for assistance.`,//`Can't login? Call us at`,
    callNumber: '(877) 203-4748',
    details: 'Monday - Friday, 8:30AM - 8:00PM ET',
    helpCenter: 'Visit the help center'
};

const digitalVaultConfigDev: DigitalVaultConfig = {
    pageSize: '50',
    maxUploadFileSize: '50',
    uploadEnabled: true,
    createFolderEnabled: true,
    documentDetailEnabled: true,
    uploadContentType: 'application/json',
    moveEnabled: false
};

const digitalVaultConfigProd: DigitalVaultConfig = {
    pageSize: '50',
    maxUploadFileSize: '50',
    uploadEnabled: true,
    createFolderEnabled: true,
    documentDetailEnabled: true,
    uploadContentType: 'application/json',
    moveEnabled: false
};

const digitalVaultConfig: DigitalVault = {
    dev: digitalVaultConfigDev,
    prod: digitalVaultConfigProd
};

const employeeAppConfigDev: EmployeeAppConfig = {
    appURL: 'http://localhost:4201'
};

const employeeAppConfigProd: EmployeeAppConfig = {
    appURL: '#{clientView}#'
};

const employeeAppConfig: EmployeeApp = {
    dev: employeeAppConfigDev,
    prod: employeeAppConfigProd
};



export const AppConfig: AppConfigMaster = {
    // auth: authConfig,
    basic: basicConfig,
    storage: storageConfig,
    route: routeConfig,
    // azureMSAL: azureMSALConfig,
    api: apiConfig,
    idleConfig: ftIdleConfig,
    support: supportConfig,
    digitalVault: digitalVaultConfig,
    employeeApp: employeeAppConfig,
    appInsights: appInsights,
    oidc: oidcAuth

};


export const tmxData = {
    tmxDomain: "pages.franklintempleton.com",
    tmxOrgId: "3gzoafui"
}
