import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { tmxData } from '../configs/app-config';
import { OktaLoginLogsModel, OktaUserModel } from '../models/user.model';
import { OktaService } from './okta.service';
import { AppService } from '@app/app.service';

@Injectable({
  providedIn: 'root'
})
export class TmxService {

  tmx_sessionId: string = '';
  tmx_client_Ip: string = '';
  tmx_login_requestId: string = '';
  tmx_iso_timeStamp: string = '';

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
    private oktaService: OktaService,
    private oktaUserModel:OktaUserModel,
    private appService:AppService) { 
    this.http = new HttpClient(handler);
  }

   doProfiling(): void {
      //TMX Code starts
      const tmx = threatmetrix;
      const md5key = md5;
      let deviceId = "2DC5753E-FC13-4336-AE14-1FB36050B79F";
      // let appVersionResponse: AppVersionResponse = new AppVersionResponse();
      let rand = Math.floor(Math.random() * 10000+1000);
      let session_id_md5=md5key("SecurePortal"+deviceId+rand+ new Date().toUTCString());
      this.tmx_sessionId = session_id_md5;
      tmx.profile(tmxData.tmxDomain, tmxData.tmxOrgId, session_id_md5);
   }

  calltmxServiceForLoginQuery(oktaLogsModel: OktaLoginLogsModel) {
    var status = oktaLogsModel.status === 'SUCCESS' ? true : false;
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiConfig.auth + 'client/login/sessionquery?accountlogin=' + oktaLogsModel.loginId + '&loginstatus=' + status, {"accountlogin":oktaLogsModel.loginId, "loginstatus":status},
        { headers: { 
                    'Authorization': 'Bearer ' + this.oktaService.accessToken,
                    'Ocp-Apim-Subscription-Key': environment.apiConfig.APIMSubscriptionKey, 
                    'X-TMX-SessionId': this.tmx_sessionId,
                    'X-Client_IpAddress': this.tmx_client_Ip },
                    observe: "response"})
        .subscribe((response: any) => {
          if (response && !response.hasError) {
            this.tmx_login_requestId = response.headers.get('X-TMX-RequestId');
            console.log("tmx_login_requestId:", this.tmx_login_requestId);
            // this.calltmxServiceForMFAQuery(oktaLogsModel)
            resolve({ status: true, data: response.body });
          } else {
            resolve({ status: false, data: response });
          }
        },
          (error: any) => {
            resolve({ status: true, data: error.error.message });
          })
    });
    
  }

  calltmxServiceForMFAQuery(oktaLogsModel: OktaLoginLogsModel) {
    var status = oktaLogsModel.status === 'SUCCESS' ? true : false;
    console.log("TMX Request ID:", this.tmx_login_requestId);
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiConfig.auth + 'client/login/updateApi?mfastatus=' + status, {"mfastatus": status},
        { headers: { 
                    'Authorization': 'Bearer ' + this.oktaService.accessToken,
                    'Ocp-Apim-Subscription-Key': environment.apiConfig.APIMSubscriptionKey, 
                    'X-TMX-SessionId': this.tmx_sessionId,
                    'X-TMX-RequestId': this.tmx_login_requestId,
                   },
                    observe: "response"})
        .subscribe((response: any) => {
          if (response && !response.hasError) {
            const tmx_resquest_id = response.headers.get('X-TMX-RequestId');
            resolve({ status: true, data: response.body, tmx_request_header: tmx_resquest_id });
          } else {
            resolve({ status: false, data: response });
          }
        },
          (error: any) => {
            resolve({ status: true, data: error.error.message });
          })
    });
    
  }

  calltmxServiceForResetPasswordQuery(oktaLogsModel: OktaLoginLogsModel) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiConfig.auth + 'client/resetpassword/sessionquery?accountlogin=' + this.oktaUserModel.emailId, {"accountlogin": this.oktaUserModel.emailId},
        { headers: { 
                    'Authorization': 'Bearer ' + this.oktaService.accessToken, 
                    'Ocp-Apim-Subscription-Key': environment.apiConfig.APIMSubscriptionKey,
                    'X-TMX-SessionId': this.tmx_sessionId,
                    'X-Client_IpAddress': this.tmx_client_Ip },
                    observe: "response"})
        .subscribe((response: any) => {
          if (response && !response.hasError) {
            const tmx_resquest_id = response.headers.get('X-TMX-RequestId');
            resolve({ status: true, data: response.body, tmx_request_header: tmx_resquest_id });
          } else {
            resolve({ status: false, data: response });
          }
        },
          (error: any) => {
            resolve({ status: true, data: error.error.message });
          })
    });
  }

  callGenericTmxService(loginLogsObj:OktaLoginLogsModel) {
    switch (loginLogsObj.eventType) {
      case "LOGIN": {
        this.doProfiling();
        this.calltmxServiceForLoginQuery(loginLogsObj);
        break;
      }
      case "MFA": {
        this.calltmxServiceForMFAQuery(loginLogsObj);
        break;
      }
      case "RESET_PASSWORD": {
        this.doProfiling();
        this.calltmxServiceForResetPasswordQuery(loginLogsObj);
        break;
      }
      default: 
        console.log("No such event type");
        break
    }   
  }

}


