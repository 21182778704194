import { Component, Inject, OnInit } from '@angular/core';
 import { Router } from '@angular/router';
 import { environment } from '@env/environment';
 import { FTNgLoadingService } from 'ft-ng-loading';
 import { AppService } from './app.service';
 import { FTLogger } from './core/logger/logger.service';
 import { OktaService } from './core/services/okta.service';
 import { SharedService } from './core/services/shared.service';
 import { TitleService } from './core/services/title-service.service';
 import { SystemService } from './core/system/system.service';
 import { OKTA_AUTH } from '@okta/okta-angular';
 import { OktaAuth, } from '@okta/okta-auth-js';
import { OktaUserModel, UserModel } from './core/models/user.model';
import { TmxService } from './core/services/tmx.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isAuthenticated: boolean = false;

  constructor(
     private router: Router,
     private appService: AppService,
     public sharedService: SharedService,
     public ftNgLoadingService: FTNgLoadingService,
     public systemService: SystemService,
     private titleService: TitleService,
     
     private oktaService: OktaService, 
     @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
     public oktaUserModel: OktaUserModel,
     public userModel:UserModel,
     public tmxService: TmxService
  ) {
      this.ftNgLoadingService.showLoader();
      this.systemService.isSystemLive = true;
      // this.appService.fetchClientIPAddress().then((response: any) => {
      //   if (response.status == true) {
      //     this.tmxService.tmx_client_Ip = response.data;
      //     this.tmxService.tmx_iso_timeStamp = new Date().toISOString();
      //   }else{
      //     this.tmxService.tmx_client_Ip = "";
      //   } 
      // });
  }

  async ngOnInit(): Promise<void> {
    this.ftNgLoadingService.hideLoader();
    this.titleService.init();
    if (environment.production) {
      FTLogger.enableProductionMode();
    }
    this.isAuthenticated = await this.oktaAuth.isAuthenticated();
    this.oktaService.accessToken = await this.oktaAuth.getAccessToken();
    if (!this.isAuthenticated) {
      this.sharedService.pageNotAuthorized = true;
    }
    
  }

}
