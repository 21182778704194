import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
 import { AppConfig } from './core/configs/app-config';
import { AuthGuard } from './core/guards/auth-guard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/' + AppConfig.route.protectedRouterUrl + '/' + AppConfig.route.defaultPage,
    pathMatch: 'full'
  },
  {
    path: AppConfig.route.protectedRouterUrl,
    loadChildren: () => import('./views/member/member.module').then(module => module.MemberModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./views/generic/maintenance/maintenance.module').then(module => module.MaintenanceModule),
    data: { title: 'Maintenance' + AppConfig.basic.pageTitleSuffix }
  },
  {
    path: 'pagenotauthorised',
    loadChildren: () => import('./views/generic/page-not-authorised/page-not-authorised.module')
      .then(module => module.PageNotAuthorisedModule),
    data: { title: 'Page Not Authorised' + AppConfig.basic.pageTitleSuffix }
  },
  {
    path: 'pagenotfound',
    loadChildren: () => import('./views/generic/page-not-found/page-not-found.module').then(module => module.PageNotFoundModule),
    data: { title: 'Page Not Found' + AppConfig.basic.pageTitleSuffix }
  },
  {
    path: 'logout',
    loadChildren: () => import('./views/authorization/logout/logout.module').then(module => module.LogoutModule),
    data: { title: 'Logged Out' + AppConfig.basic.pageTitleSuffix }
  },
  {
    path: 'enroll',
    loadChildren: () => import('./views/authorization/enrollment/enrollment.module').then(module => module.EnrollmentModule),
    data: { title: 'Enrollment' + AppConfig.basic.pageTitleSuffix }
  },
  {
    path: 'helpcenter',
    loadChildren: () => import('./views/generic/help-center/help-center.module').then(module => module.HelpCenterModule),
    data: { title: 'Help Center' + AppConfig.basic.pageTitleSuffix },
  },
  {
    path: 'healthcheck',
    loadChildren: () => import('./views/generic/healthcheck/healthcheck.module').then(module => module.HealthcheckModule),
    data: { title: 'Health Check' + AppConfig.basic.pageTitleSuffix },
  },
  {
    path:'login/callback',
    redirectTo: '/' + AppConfig.route.protectedRouterUrl + '/' + AppConfig.route.defaultPage,
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./views/authorization/login/login.module').then(module => module.LoginModule),
  },
  {
    path:'**',
    redirectTo: 'pagenotfound'
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
