import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  /**
   * Shortest name:  {@link sortFunction}
   * Full name:      {@link (sortFunction: any)}
   * @description Sorts an array of menu objects based on display order and returns sorted array.
   * @param data: any
   */
  sortFunction(data: any, key: string) {
    data.sort((a: any, b: any) => {
      return a[key] - b[key];
    });
    return data;
  }

  /**
   * Shortest name:  {@link resizeEventDispatcher}
   * Full name:      {@link (resizeEventDispatcher: void)}
   * @description resize event dispatcher.
   */
  resizeEventDispatcher() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('msie') !== -1 || navigator.appVersion.toLowerCase().includes('trident')) {
      const evt = document.createEvent('UIEvents');
      evt.initEvent('resize', true, false);
      window.dispatchEvent(evt);
    } else {
      window.dispatchEvent(new Event('resize'));
    }
  }

  /**
   * Shortest name:  {@link lightenDarkenColor}
   * Full name:      {@link (lightenDarkenColor: void)}
   * @description reset color.
   * @param colorCode: any
   * @param amount: any
   */
  lightenDarkenColor(colorCode, amount) {
    let usePound = false;
    if (colorCode[0] === '#') {
      colorCode = colorCode.slice(1);
      usePound = true;
    }

    const num = parseInt(colorCode, 16);
    let r = (num >> 16) + amount;
    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    let b = ((num >> 8) & 0x00FF) + amount;
    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }

    let g = (num & 0x0000FF) + amount;
    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  }

  /**
   * Shortest name:  {@link isBrowserMsIe}
   * Full name:      {@link (isBrowserMsIe: void)}
   * @description check if browser is IE.
   */
  isBrowserMsIe(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return (userAgent.indexOf('msie') !== -1 || navigator.appVersion.toLowerCase().includes('trident'));
  }

  /**
   * Shortest name:  {@link slideToggleChangeHandler}
   * Full name:      {@link (slideToggleChangeHandler: void)}
   * @description check if browser is Edge.
   */
  isBrowserMsEdge(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return (userAgent.indexOf('edge') > -1);
  }

  public getFileSizeFormat(bytes) {
    if (!bytes || bytes === 0) {
      return '0 Bytes';
    }
    const k = 1000;
    const dm = 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /**
   * Shortest name:  {@link getMimeContentType}
   * Full name:      {@link (getMimeContentType:string)}
   * @description get the MimeContentType based on the selected fileType.
   * @param fileType: string - set the filetype format(pdf/xlsx) for the selected document.
   */
  getMimeContentType(fileType: string): string {
    switch (fileType) {
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'tif':
      case 'tiff':
        return 'image/' + fileType;
        break;
      case 'pdf':
        return 'application/pdf';
        break;
      case 'xlsx':
        return 'octet/stream'; // for xlsx 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        break;
      case 'doc':
        return 'application/msword';
        break;
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;
      case 'ppt':
        return 'application/vnd.ms-powerpoint';
        break;
      case 'pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        break;
      case 'pps':
        return 'application/vnd.ms-powerpoint';
        break;
      case 'txt':
        return 'text/plain';
        break;
      case 'heic':
        return 'heic';
        break;
      default:
        break;
    }
  }
}
