import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { OktaService } from '../services/okta.service';
import { Router } from '@angular/router';
import { FTLogger } from '@core/logger/logger.service';
import { MatDialog } from '@angular/material/dialog';

const log = new FTLogger('App Service');

@Injectable()
export class HttpInterceptProvider {
  constructor(
    private http: HttpClient,
    private oktaService:OktaService,
    private router: Router,
    private dialog: MatDialog
  ) {
   }

  getHeaders(options?: any): any {
    let httpHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': environment.apiConfig.APIMSubscriptionKey,
      'Ocp-Apim-Trace': 'true',
      'Authorization': 'Bearer ' + this.oktaService.accessToken
    });
    // if (options) { 
    //   if (options.headers) {
    //     options.headers.keys().forEach((header: any) => {
    //       httpHeaders = httpHeaders.append(header, options.headers.get(header));
    //     });
    //   }
    // }

    // if (options) {
    //   if (options.responseType) {
    //     httpHeaders.append('responseType', options.responseType);
    //   }
    //   if (options.ChannelType) {
    //     httpHeaders.append('ChannelType', options.ChannelType);
    //   }
    // }

    if (sessionStorage.getItem(environment.storagePrefix + 'asofdate')) {
      httpHeaders.append('asOfDate', sessionStorage.getItem(environment.storagePrefix + 'asofdate'));
    }

    if (sessionStorage.getItem(environment.storagePrefix + 'sessionId')) {
      httpHeaders.append('sessionId', sessionStorage.getItem(environment.storagePrefix + 'sessionId'));
    }

    return { headers: httpHeaders };
  }

  get(url: string, options?: any): Observable<any> {
    const headerOptions: any = this.getHeaders(options);
    return this.http.get(url, headerOptions).pipe(map(data => data),catchError(error => {
      if(error.status == 401 || error.status == 403){
        this.router.navigate(['/pagenotauthorised']);
        this.dialog.closeAll();
      } else {
        log.error('Error  ' + error);
        return throwError(error);
      }
    }));
  }

  post(url: string, body: any, options?: any): Observable<any> {
    const headerOptions: any = this.getHeaders(options);
    return this.http.post(url, body, headerOptions).pipe(map(data => data),catchError(error => {
      if(error.status == 401 || error.status == 403){
        this.router.navigate(['/pagenotauthorised']);
        this.dialog.closeAll();
      } else {
        log.error('Error  ' + error);
        return throwError(error);
      }
    }));
  }

  put(url: string, body: any, options?: any): Observable<any> {
    const headerOptions: any = this.getHeaders(options);
    return this.http.put(url, body, headerOptions).pipe(map(data => data),catchError(error => {
      if(error.status == 401 || error.status == 403){
        this.router.navigate(['/pagenotauthorised']);
        this.dialog.closeAll();
      } else {
        log.error('Error  ' + error);
        return throwError(error);
      }
    }));
  }

  delete(url: string, options?: any): Observable<any> {
    const headerOptions: any = this.getHeaders(options);
    return this.http.delete(url, headerOptions).pipe(map(data => data),catchError(error => {
      if(error.status == 401 || error.status == 403){
        this.router.navigate(['/pagenotauthorised']);
        this.dialog.closeAll();
      } else {
        log.error('Error  ' + error);
        return throwError(error);
      }
    }));
  }

  downloadFile(url: string, body: any, fileType: any, options?: any): Observable<any> {
    const responseTypeParam: any = fileType === 'xlsx' ? 'blob' : 'blob' as 'json';
    const headerOptions: any = this.getHeaders(options);
    headerOptions.responseType = responseTypeParam;
    return this.http.post(url, body, headerOptions);
  }
}
