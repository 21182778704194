import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AppConfig } from '../configs/app-config';
import { UtilsService } from '../services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  public isSystemLive: boolean;
  public userMenu: any = [];

  /* System Alerts */
  public systemAlertConfig: any = {
    systemAlertShown: false,
    config: {
      type: 'critical'
    },
    dataObject: null
  };

  /* Flash Alert */
  public flashAlertConfig: any = {
    alertShown: false,
    alertContent: '',
    alertTitle: '',
    alertID: '',
  };

  /* Header */
  public headerConfig = {
    show: false,
    dynamic: true,
    isLogin: true,
    title: 'Franklin Templeton',
    logo: 'assets/imgs/logos/' + AppConfig.basic.logo,
    logoSymbol: 'assets/imgs/logos/' + AppConfig.basic.logoSymbol,
    projectTitle: '', // 'Starter Project',
    protectedRouterUrl: AppConfig.route.protectedRouterUrl,
    showHelpIcon: false
  };

  /* Footer */
  public footerConfig = {
    show: false
  };
  public loggedInfooterObject = {
    copyRightText: 'Copyright 2020 Franklin Templeton. All Rights Reserved.',
    imagePath:'assets/imgs/logos/' + AppConfig.basic.logoWhite,//'https://azuswstgmyfidevassets.blob.core.windows.net/secportalassets/ft-logo-white.png',
    // disclaimerTexts: [{
    //   text: 'Securities, mutual funds and other non-deposit investments:',
    //   subText: '',
    //   items: ['Not FDIC Insured', 'No Bank Guarantee', 'May Lose Value'],
    //   linktype: '',
    //   linkurl: '',
    //   link: '',
    //   cssClass: 'info-text'
    // }, {
    //   text: `Fiduciary Trust Company International, headquartered in New York,
    //    (and subsidiaries doing business as Fiduciary Trust International) and FTCI (Cayman) Ltd. are part of the `,
    //   subText: ' family of companies.',
    //   items: [],
    //   linktype: 'external',
    //   link: 'https://www.franklintempleton.com/',
    //   linkText: 'Franklin Templeton',
    //   cssClass: 'link-text'
    // }],
    links: [
      // {
      //   text: 'Site Help',
      //   link: '/' + AppConfig.route.protectedRouterUrl + '/helpcenter',
      //   displayorder: 2,
      //   align: 'left',
      //   linktype: 'internal',
      //   isAuthorised: true
      // },
      {
        text: 'Contact Us',
        link: 'https://www.fiduciarytrust.com/contact-us',
        displayorder: 2,
        align: 'left',
        linktype: 'external',
        isAuthorised: true
      },
      {
        text: 'Privacy Policy',
        link: 'https://www.franklintempletonglobal.com/privacy',
        displayorder: 3,
        align: 'left',
        linktype: 'external',
        isAuthorised: true
      },
      {
        text: 'Terms of Use',
        link: 'https://www.fiduciarytrust.com/disclosure',
        displayorder: 4,
        align: 'left',
        linktype: 'external',
        isAuthorised: true
      },
      {
        text: 'Anti-Corruption Policy',
        link: 'https://www.fiduciarytrust.com/feedback-footer',
        displayorder: 5,
        align: 'left',
        linktype: 'external',
        isAuthorised: true
      },
    ],
    // showMobileAppLinks: true,
    // mobileAppLinks: [
    //   {
    //     text: 'Apple',
    //     link: 'https://apps.apple.com/us/app/fiduciary-trust-international/id1501678642',
    //     linktype: 'external',
    //     altValue: 'Apple app_store',
    //     imagePath: 'assets/imgs/app_store.svg'
    //   },
    //   {
    //     text: 'Google',
    //     link: 'https://play.google.com/store/apps/details?id=com.fiduciarytrust.oc',
    //     linktype: 'external',
    //     altValue: 'google play_store',
    //     imagePath: 'assets/imgs/google_play_store.svg'
    //   }
    // ],
    // showSocialLinks: true,
    // socialLinks: [
    //   {
    //     text: 'linkedin',
    //     link: 'https://www.linkedin.com/company/fiduciary-trust-company/',
    //     linktype: 'external',
    //     iconClass: 'ftci-icon-linkedin'
    //   },
    //   {
    //     text: 'twitter',
    //     link: 'https://twitter.com/FiduciaryTrust',
    //     linktype: 'external',
    //     iconClass: 'ftci-icon-twitter-logo'
    //   }
    // ]
  };

  public nonLoggedInfooterObject = {
    copyRightText: 'Copyright 2020 Franklin Templeton. All Rights Reserved.',
    // disclaimerTexts: [{
    //   text: 'Securities, mutual funds and other non-deposit investments:',
    //   subText: '',
    //   items: ['Not FDIC Insured', 'No Bank Guarantee', 'May Lose Value'],
    //   linktype: '',
    //   linkurl: '',
    //   link: '',
    //   cssClass: 'info-text'
    // }, {
    //   // tslint:disable-next-line: max-line-length
    //   text: 'Fiduciary Trust Company International, headquartered in New York, (and subsidiaries doing business as Fiduciary Trust International) and FTCI (Cayman) Ltd. are part of the ',
    //   subText: ' family of companies.',
    //   items: [],
    //   linktype: 'external',
    //   link: 'https://www.franklintempleton.com/',
    //   linkText: 'Franklin Templeton',
    //   cssClass: 'link-text'
    // }],
    // links: [
    //   {
    //     text: 'Site Help',
    //     link: '/helpcenter',
    //     displayorder: 2,
    //     align: 'left',
    //     linktype: 'internal',
    //     isAuthorised: false
    //   },
    //   {
    //     text: 'Contact Us',
    //     link: 'https://www.fiduciarytrust.com/contact-us',
    //     displayorder: 3,
    //     align: 'left',
    //     linktype: 'external',
    //     isAuthorised: false
    //   },
    //   {
    //     text: 'Complaints',
    //     link: 'https://www.fiduciarytrust.com/feedback-footer',
    //     displayorder: 4,
    //     align: 'left',
    //     linktype: 'external',
    //     isAuthorised: false
    //   },
    //   {
    //     text: 'Terms of Use',
    //     link: 'https://www.fiduciarytrust.com/disclosure',
    //     displayorder: 5,
    //     align: 'left',
    //     linktype: 'external',
    //     isAuthorised: false
    //   },
    //   {
    //     text: 'Disclosures',
    //     link: environment.apiConfig.resourceURL + 'assets/Fiduciary_Trust_Disclosure_Statement.pdf',
    //     displayorder: 6,
    //     align: 'left',
    //     linktype: 'external',
    //     isAuthorised: false
    //   },
    //   {
    //     text: 'Privacy',
    //     link: 'https://www.fiduciarytrust.com/disclosure#w1454393765803',
    //     displayorder: 7,
    //     align: 'left',
    //     linktype: 'external',
    //     isAuthorised: false
    //   }
    // ],
    // showMobileAppLinks: true,
    // mobileAppLinks: [
    //   {
    //     text: 'Apple',
    //     link: 'https://apps.apple.com/us/app/fiduciary-trust-international/id1501678642',
    //     linktype: 'external',
    //     altValue: 'Apple app_store',
    //     imagePath: 'assets/imgs/app_store.svg'
    //   },
    //   {
    //     text: 'Google',
    //     link: 'https://play.google.com/store/apps/details?id=com.fiduciarytrust.oc',
    //     linktype: 'external',
    //     altValue: 'google play_store',
    //     imagePath: 'assets/imgs/google_play_store.svg'
    //   }
    // ],
    // showSocialLinks: true,
    // socialLinks: [
    //   {
    //     text: 'linkedin',
    //     link: 'https://www.linkedin.com/company/fiduciary-trust-company/',
    //     linktype: 'external',
    //     iconClass: 'ftci-icon-linkedin'
    //   },
    //   {
    //     text: 'twitter',
    //     link: 'https://twitter.com/FiduciaryTrust',
    //     linktype: 'external',
    //     iconClass: 'ftci-icon-twitter-logo'
    //   }
    // ]
  };
  /* Footer End */

  constructor(
    private utilsService: UtilsService
  ) { }

  /**
   * Shortest name:  {@link setUserMenu}
   * Full name:      {@link (setUserMenu: void)}
   * @description set user menu.
   * @param userMenu: any
   */
  setUserMenu(userMenu: any) {
    AppConfig.route.defaultPage = userMenu.defaultPage;
    if (userMenu.menu && userMenu.menu.length > 0) {
      userMenu.menu.forEach((menuItem: any) => {
        menuItem.link = '/' + AppConfig.route.protectedRouterUrl + menuItem.link;
        if (menuItem.menu) {
          menuItem.menu.forEach((subMenuItem: any) => {
            subMenuItem.link = '/' + AppConfig.route.protectedRouterUrl + subMenuItem.link;
          });
        }
      });
      // this.userMenu = userMenu.menu;
      this.userMenu = this.utilsService.sortFunction(userMenu.menu, 'displayorder');
      userMenu.menu.forEach((obj: any) => {
        if (obj.menu && obj.menu.length) {
          this.utilsService.sortFunction(obj.menu, 'displayorder');
        }
      });
    }
  }
}
