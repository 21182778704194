export class UserModel {
  userName?: string;
  accessToken?: string;
  AsOfDate = '2017-01-01';
  cuid?: string;
  userId?: string;
  uid?: string;
  uuid?: string;
  userProfile?: UserProfileModel;
  oktaUserModel: OktaUserModel;
}

export class UserProfileModel {
  clientid: string;
  cuid: string;
  forceconsent: boolean;
  forcemfa: boolean;
  forcepasswordchange: boolean;
  id: number;
  objectid: string;
  profiletypeid: number;                
  renewMFA: boolean;
  requiremfa: boolean;
  tiaraid: string;
  username: string;
  uuid: string;
  emailId: string;
  contactNo: string;
}

export class OktaUserModel {
  userName?: string;
  accessToken?: string;
  refreshToken?: string;
  AsOfDate = '2017-01-01';
  emailId?: string;
}

export class OktaLoginLogsModel {
  eventType: string;
  status: string;
  reason: string;
  loginId: string;
}